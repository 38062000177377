import { PortableTextTypeComponentProps } from "@portabletext/react";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@reach/disclosure";
import PortableText from "components/PortableText/PortableText";
import useIntersection from "hooks/useIntersection";
import { useSemiControlledExpand } from "hooks/useSemiControlledExpand";
import { useRouter } from "next/router";
import { RefObject, useEffect, useRef, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
// eslint-disable-next-line no-restricted-imports
import { Palette } from "@mui/material/styles/createPalette";
import { RealtimeGuidelinesType, useGetHDIRGuidelines } from "data/contentData/hooks/hdir.hooks";
type PropsType = PortableTextTypeComponentProps<RealtimeGuidelinesType>;
export default function RealtimeGuideline({
  value
}: PropsType) {
  const router = useRouter();
  const ref = useRef<HTMLButtonElement>(null);
  const {
    constants
  } = useTheme();
  const a = useIntersection(ref as RefObject<HTMLElement>, {
    rootMargin: `${-constants.height.header}px 0px 200px 0px`,
    threshold: 0.1
  });
  const [inView, setInView] = useState(false);
  const isIntersecting = a?.isIntersecting;
  useEffect(() => {
    if (isIntersecting && !inView) {
      setInView(true);
    }
  }, [inView, isIntersecting]);
  const {
    data
  } = useGetHDIRGuidelines(value.id, router.asPath, {
    enabled: inView
  });
  const {
    content,
    source
  } = data ? {
    content: data.blockContent ? <PortableText value={JSON.parse(data.blockContent)} /> : null,
    source: data.source
  } : {
    content: <RealtimeGuidelineSkeleton />,
    source: <Skeleton />
  };
  const {
    open,
    onChange
  } = useSemiControlledExpand(value.id, value.collapsed ?? false);
  return <Disclosure defaultOpen={false} id={value.id} open={open} onChange={onChange} data-sentry-element="Disclosure" data-sentry-component="RealtimeGuideline" data-sentry-source-file="RealtimeGuideline.tsx">
            <Summary notestyle={value.style} ref={ref} data-sentry-element="Summary" data-sentry-source-file="RealtimeGuideline.tsx">
                <Typography variant="h4" data-sentry-element="Typography" data-sentry-source-file="RealtimeGuideline.tsx">{value.title}</Typography>
            </Summary>
            <Panel notestyle={value.style} data-sentry-element="Panel" data-sentry-source-file="RealtimeGuideline.tsx">
                {content}
                <Source variant="body2" align="right" data-sentry-element="Source" data-sentry-source-file="RealtimeGuideline.tsx">
                    {source}
                </Source>
            </Panel>
        </Disclosure>;
}
function RealtimeGuidelineSkeleton() {
  return <>
            <Skeleton height={24} data-sentry-element="Skeleton" data-sentry-source-file="RealtimeGuideline.tsx" />
            <br />
            <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="RealtimeGuideline.tsx" />
            <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="RealtimeGuideline.tsx" />
            <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="RealtimeGuideline.tsx" />
            <br />
        </>;
}
const Summary = styled(DisclosureButton)<{
  notestyle: string;
}>(({
  notestyle,
  theme
}) => {
  const {
    palette
  } = theme;
  const {
    summary,
    border
  } = palette.notes[notestyle as keyof Palette["notes"]];
  return {
    position: "relative",
    display: "flex",
    width: "100%",
    minHeight: 48,
    boxShadow: "none",
    padding: theme.spacing(1, 2, 1.25),
    cursor: "pointer",
    textAlign: "left",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: summary.main,
    border: border && `1px solid ${border}`,
    "&::after": {
      fontSize: "0.8rem",
      marginRight: "0.25rem",
      transform: "translateY(-0.125rem) rotate(90deg)",
      transition: "150ms cubic-bezier(0.4, 0, 0.2, 1)",
      // https://github.com/mui-org/material-ui/issues/14153#issuecomment-453605145
      content: '"❯"',
      "@media print": {
        display: "none !important"
      }
    },
    '&[data-state="open"]::after': {
      transform: "rotate(270deg)"
    },
    "@media print": {
      background: "transparent",
      color: "black !important"
    },
    // Headers with BlockContent have a p tag as a child
    "& > p": {
      margin: "0 !important"
    },
    "& > h4, > h4 > p, > span, > span > p": {
      margin: "0 !important",
      fontWeight: 700,
      fontSize: "1rem"
    }
  };
});
const Panel = styled(DisclosurePanel)<{
  notestyle: string;
}>(({
  notestyle,
  theme
}) => {
  const {
    palette,
    spacing
  } = theme;
  const {
    details,
    border
  } = palette.notes[notestyle as keyof Palette["notes"]];
  return {
    backgroundColor: details,
    borderRight: border && `1px solid ${border}`,
    borderBottom: border && `1px solid ${border}`,
    borderLeft: border && `1px solid ${border}`,
    padding: spacing(2),
    marginTop: "-16px",
    marginBottom: spacing(2)
  };
});
const Source = styled(Typography)(({
  theme
}) => ({
  fontWeight: 700,
  marginTop: theme.spacing(2)
}));