import { get } from "lib/http/http"
import { User } from "next-auth"
import { JWT } from "next-auth/jwt"

export interface IOrder {
    data: {
        id: string
        user_id: string
        order_number: string
        status: "PENDING" | "PAID" | "CANCELED"
        product: {
            id: number
            name: string
        }
        created_at: string
    }[]
    total: number
}

interface IProlexClient {
    client_id: string
    display_name: string
    base_url: string
}

interface IProlexFile {
    original_name: string
    name: string
    size: number
    bytes: number[]
    link: string
}

interface IProlexSpecialization {
    id: string
    sub_specializations: IProlexSpecialization[]
}

interface IProlexAreaOfInterest {
    id: string
    sub_area_of_interest: IProlexAreaOfInterest[]
}

interface IProlexTicket {
    status: string
    type: string
    approved_by: string
    submitted_date: string
    closed_date: string
    probation_end_period: string
}

export interface IProlexUser {
    id: string
    username: string
    enable: boolean
    email_verified: boolean
    frist_name: string
    last_name: string
    email: string
    realm_id: string
    clients: IProlexClient[]
    occupation_id: string
    workplace_id: string
    medical_chamber_id: string
    university_id: string
    year_of_graduation: number
    license_type: string
    license_number: string
    terms_and_conditions: boolean
    license_files: IProlexFile[]
    specializations: IProlexSpecialization[]
    area_of_interests: IProlexAreaOfInterest[]
    date_of_birth: string
    created_at: string
    last_login: string
    updated_at: string
    confirmed_at: string
    last_opened_email: string
    ticket: IProlexTicket[]
    newsletters: Map<string, boolean>
}

export async function getActiveCDSOrders(
    token: string | null | undefined
): Promise<IOrder | null> {
    const url = `${process.env.NEXT_PUBLIC_PROLEX_SHOP_API}/user/orders?is_expired=false&status=PAID&client_id=cds-ui&type=SUBSCRIPTION`
    const { data } = await get<IOrder | null>(url, token)
    return data
}

/*
user: User | undefined
accessToken: JWT["accessToken"]


*/

export async function getProlexUser(
    user: User | undefined,
    accessToken: JWT["accessToken"] | undefined
): Promise<IProlexUser | null> {
    const userId = user?.guid

    if (userId) {
        const url = `${process.env.NEXT_PUBLIC_AUTH_ISSUER}/realms/${realmFromLocale()}/admin-api/users/${userId}`

        const { data } = await get(url, accessToken)

        if (data) return data as IProlexUser
    }

    return null
}

function realmFromLocale() {
    switch (process.env.NEXT_PUBLIC_LOCALE) {
        case "no":
            return "norway"
        case "pl":
            return "poland"
        case "de":
            return "germany"
        case "fr":
            return "france"
        case "se":
            return "sweden"
        default:
            return "master"
    }
}
