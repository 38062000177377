import { ICustomerManagerOrganisation } from "data/customerManager/api/organisation"
import { get } from "lib/http/http"

/**
 * Get organisation from "internal" api endpoint
 * @returns Organisation if it exists.
 */
export async function getOrganisationInternal() {
    try {
        const { data } = await get<ICustomerManagerOrganisation | null>(
            "/api/organisation"
        )
        return data
    } catch {
        return null
    }
}
