import { auth } from "config/config"
import useUser from "hooks/useUser"
import { useQuery } from "react-query"

import { ICustomerManagerOrganisation } from "data/customerManager/api/organisation"
import { isAuthorized } from "auth/authorization"
import { getOrganisationInternal } from "data/internal/api/organisation"

const OrganisationCacheKeys = {
    ORGANISATION_IP: "organisation-ip"
}

export interface IOrganisation {
    authenticated: boolean
    roles: string[]
    isLoading: boolean
    organisation: ICustomerManagerOrganisation | null | undefined
}

/**
 * Gets an organisation via IP / User organisation affiliation (implicit on api endpoint)
 * @returns
 */
export function useGetOrganisation(): IOrganisation {
    const { user, isFetching: isFetchingUser } = useUser()

    // Enable query if the following conditions are met
    // - CDS has IP access enabled in config
    // - user is not subscriber
    // - is not fetching user
    const enabled =
        auth.hasIPAccess &&
        !isAuthorized(user?.roles, auth.roles.subscriber) &&
        !isFetchingUser

    const { data, isLoading } = useQuery<
        ICustomerManagerOrganisation | null,
        Error
    >(
        [OrganisationCacheKeys.ORGANISATION_IP],
        async () => await getOrganisationInternal(),
        {
            enabled,
            retry: false
        }
    )

    let roles: string[] = []
    if (data) {
        roles =
            data.claims
                ?.filter(
                    p =>
                        p.key ===
                        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                )
                .map(p => p.value) ?? []
    }

    const result = {
        organisation: data,
        roles,
        authenticated: !isLoading && !!data,
        isLoading
    }

    return result
}
